import { useEffect, useState } from 'react'
import { useHistoryContext } from '../useHistoryContext'
import { CommerceBillingCycleClient } from '@monetization/hpaip-notification-rules-react'

export const useGetCustomer = (id: string) => {
  const { authProvider, stack } = useHistoryContext()
  const [getCustomerInfo, setGetCustomerInfo] = useState(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const getCustomer = async () => {
      setIsLoading(true)
      try {
        const commerceBillingCycleClient = new CommerceBillingCycleClient(
          authProvider,
          stack
        )
        const { resourceId } =
          await commerceBillingCycleClient.getCustomerDetailsById(id)
        setGetCustomerInfo(resourceId)
        setIsLoading(false)
      } catch (error) {
        console.log(error, 'error')
        setError(true)
        setIsLoading(false)
      }
    }

    getCustomer()
  }, [authProvider, stack, id])

  return {
    customerId: getCustomerInfo,
    error,
    isLoading
  }
}
