import { useEffect, useState } from 'react'
import { useHistoryContext } from '../useHistoryContext'
import { CommerceBillingCycleClient } from '@monetization/hpaip-notification-rules-react'

export const useGetStatements = (id: any) => {
  const { authProvider, stack } = useHistoryContext()
  const [getAllStatementsInfo, setGetAllStatementsInfo] = useState(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const getAllStatements = async () => {
      setIsLoading(true)
      try {
        const commerceBillingCycleClient = new CommerceBillingCycleClient(
          authProvider,
          stack
        )
        const getAllStatementsData =
          await commerceBillingCycleClient.getInvoiceData(id)
        setGetAllStatementsInfo(getAllStatementsData)
        setIsLoading(false)
      } catch (error) {
        console.log(error, 'error')
        setError(true)
        setIsLoading(false)
      }
    }

    getAllStatements()
  }, [authProvider, stack, id])

  return {
    data: getAllStatementsInfo,
    error,
    isLoading
  }
}
